.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;

	.header {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.title {
			font-size: 24px;
			line-height: 30px;
			letter-spacing: -0.5px;
			color: #153B34;
			font-weight: 700;
		}
		
		.description {
			font-size: 20px;
			line-height: 24px;
			letter-spacing: -0.5px;	
			color: #153B34;
		}
	}
	
	.body {
		width: 689px;
		min-width: 100%;
		height: auto;
		overflow: hidden;
		border-radius: 38px;

		.thumbnail {
			display: flex;
			justify-content: center;
			height: 100%;
			cursor: pointer;

			& img {
				height: 100%;
				width: 762px;
				max-width: none;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.container {

		.header {

			.title {
				font-size: 20px;
				line-height: 24px;
				letter-spacing: -0.5px;
			}

			.description {
				font-size: 16px;
				line-height: 22px;
				letter-spacing: -0.5px;

			}
		}

		.body {
			width: 100%;
			height: 179px;
			border-radius: 12px;

			.thumbnail {
				height: 100%;
				justify-content: flex-start;
	
				& img {
					height: 100%;
				}
			}
		}
	}


}
